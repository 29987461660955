export default {
  "common": {
    "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleccionar"])},
    "goHome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ir a Inicio"])},
    "selectOption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleccionar una opción"])},
    "noData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No hay información disponible"])}
  },
  "titles": {
    "welcome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bienvenido(a)"])},
    "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ticket Dancer - ¿Dónde quieres bailar?"])},
    "goToHome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inicio"])},
    "enter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingresa a Ticket Dancer"])},
    "myAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mi cuenta"])},
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Iniciar sesión"])},
    "createAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crea tu cuenta"])},
    "personalSettings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datos personales"])},
    "purchases": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mis compras"])},
    "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salir"])},
    "forgotPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recupera tu acceso a Ticket Dancer"])}
  },
  "myAccount": {
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingresar"])},
    "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crea tu cuenta"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correo electrónico"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contraseña"])},
    "currency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Moneda preferida"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre(s)"])},
    "lastname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apellidos"])},
    "passwordConfirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmar contraseña"])},
    "createDisclosure1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En Ticket Dancer deseamos mantenerte informado con correos electrónicos sobre eventos y promociones. Puedes modificar esta configuración en tu página de \"Ajustes\". Consulta nuestra "])},
    "createDisclosure2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Política de Privacidad."])},
    "createDisclosure3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Declaro haber leído y aceptado las "])},
    "createDisclosure4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Condiciones de uso."])},
    "createUserConfirmation1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Deseas usar el siguiente correo electrónico?"])},
    "createUserConfirmation2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No podrás cambiar de correo electrónico posteriormente."])},
    "createUserNotification": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Recibirás un correo de parte de Ticket Dancer a ", _interpolate(_list(0)), " para verificar tu cuenta. Por favor revisa tu bandeja de entrada (o Spam) y sigue las instrucciones."])},
    "createUserButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crear cuenta"])},
    "resendNewEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enviar nuevo correo"])},
    "newPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nueva contraseña"])},
    "enterNewPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingresa una nueva contraseña. Recuerda los requisitos necesarios de la contraseña."])},
    "reenterNewPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reingresa tu nueva contraseña."])},
    "updateYourAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actualiza tus datos personales"])},
    "personalData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datos de usuario"])},
    "advancedSettings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajustes avanzados"])},
    "changePassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cambiar contraseña"])},
    "currentPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contraseña actual"])},
    "successUpdate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tu usuario ha sido actualizado"])},
    "deleteAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eliminar cuenta"])},
    "deleteAccoountDisclosure1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Realmente quieres eliminar tu cuenta? Toda tu información será borrada permanentemente, incluyendo todos los pagos y las compras."])},
    "deleteAccoountDisclosure2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¡Esto no puede ser revertido!"])},
    "successDeletion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tu cuenta fue borrada exitosamente"])},
    "logoutConfirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cerraste sesión exitosamente"])}
  },
  "currencies": {
    "mxn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["🇲🇽 - Pesos mexicanos"])},
    "usd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["🇺🇸 - Dólares estadounidenses"])}
  },
  "forgoPassword": {
    "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Olvidé mi contraseña"])},
    "title1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Olvidaste tu contraseña?"])},
    "instructions1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Te enviaremos un código de reestablecimiento a tu correo. Ingresa el correo electrónico con el que te registaste a Ticket Dancer."])},
    "instructions1button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enviar código"])},
    "title2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ya tengo un código"])},
    "instructions2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingresa tu correo en el campo de arriba y el código que recibiste en tu correo aquí."])},
    "instructions2TF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingresa código (un solo uso)"])},
    "instructions2button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Validar código"])},
    "codeSent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hemos enviado el código de acceso al correo proporcionado. En breve lo recibirás en tu bandeja de entrada."])}
  },
  "events": {
    "allEventsTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Todos los eventos"])},
    "selectTicketsButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adquiere tus boletos"])},
    "durationDate": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Del ", _interpolate(_list(0)), " al ", _interpolate(_list(1))])},
    "loadMoreButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cargar más eventos..."])},
    "artists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Artistas estelares"])},
    "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Información del evento"])},
    "location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sede"])},
    "selectTicketTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecciona un boleto"])},
    "noTickets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No se obtuvieron boletos disponibles para este evento. Inténtalo más tarde."])},
    "ticketIncludes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cada boleto incluye:"])},
    "ticketQuantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cantidad:"])},
    "ticketTotal": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Total: ", _interpolate(_list(0))])},
    "showTotalDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["+ Mostrar detalles"])},
    "hideTotalDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["- Ocultar detalles"])},
    "pricePerTicket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Precio por boleto:"])},
    "feePerTicket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Servicio por boleto:"])},
    "pricePerRegistry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Precio por registro:"])},
    "feePerRegistry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Servicio por registro:"])}
  },
  "registries": {
    "selectRegistriesButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Otros Pagos y Registros"])},
    "noRegistries": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No se obtuvieron otros pagos y registros disponibles para este evento. Inténtalo más tarde."])},
    "selectRegisterTypeTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Realiza un Pago o Registro"])},
    "selectRegisterTypeItemTitle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Registro de ", _interpolate(_list(0))])},
    "registrationIncludes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cada registro incluye:"])},
    "registriesSubtotal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subtotal de registros:"])},
    "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Registro (Pago) - ", _interpolate(_list(0)), " - ", _interpolate(_list(1))])},
    "fillInformation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Completa la siguiente información"])},
    "generalData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datos generales (opcional)"])},
    "participantsSubtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personas a registrar"])},
    "allNamesRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Al menos todos los nombres completos son requeridos*"])},
    "fullName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre completo"])},
    "age": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edad"])},
    "genreH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hombre"])},
    "genreM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mujer"])},
    "state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estado"])},
    "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ciudad"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correo electrónico"])}
  },
  "shoppingCart": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Orden de Compra"])},
    "noActivePO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No tienes ninguna Orden de Compra activa"])},
    "creating": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creando Orden de Compra..."])},
    "activeShoppingCartFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ya tienes una Order de Compra activa. ¿Quieres continuar con la anterior o quieres crear una nueva?"])},
    "remainingTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tiempo restante"])},
    "selectPaymentMethod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecciona el método de pago"])},
    "payNow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Completar pago"])},
    "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detalles de la compra"])},
    "concept": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Concepto de pago:"])},
    "quantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cantidad:"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descripción:"])},
    "ticketSubtotal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subtotal de boletos:"])},
    "feeSubtotal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subtotal de servicio*:"])},
    "nonRefundable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["* Servicio no reembolsable"])},
    "couponRedeemed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descuento aplicado:"])},
    "couponName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Código de descuento"])},
    "redeem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Canjear"])},
    "redeemSuccessful": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Código aplicado"])},
    "removeCouponSuccessful": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Código removido exitosamente"])},
    "assignTickets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asigna tus boletos"])},
    "companyGroup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grupo o Compañía de baile"])},
    "participant": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), ". Nombre completo del asistente"])},
    "allNamesRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Todos los nombres son requeridos*"])},
    "timeout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se agotó el tiempo para completar la Orden. Vuelve a agregar boletos al Carrito."])},
    "deleteButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eliminar Orden de Compra"])},
    "exitConfirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Deseas eliminar esta Orden de Compra?"])},
    "savedCards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tarjetas guardadas para este evento"])},
    "addPaymentMethod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usar otro método de pago"])},
    "addCard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agregar nueva tarjeta ahora"])},
    "addCardDisclaimer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["* Necesario para mostrar los planes de pago disponibles"])},
    "cardholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre que aparece en la tarjeta"])}
  },
  "plans": {
    "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cargando planes de pago disponibles..."])},
    "plansAvailable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["* Planes de pago disponibles para esta tarjeta"])},
    "selectPlan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleccionar plan de pago"])},
    "none": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Un solo pago de ", _interpolate(_list(0))])},
    "multiple": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " pagos mensuales de ", _interpolate(_list(1))])},
    "msiAvailable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meses sin intereses disponibles"])},
    "msiAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["* Meses sin intereses disponibles en toda la tienda"])},
    "fixed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " pagos fijos"])},
    "msi": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " meses sin intereses"])}
  },
  "paymentStatus": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estatus de compra"])},
    "thanks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¡GRACIAS!"])},
    "tryAgainButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intentar de nuevo"])},
    "successTickets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tus boletos están en camino. En cuanto confirmemos tu pago los recibirás en tu correo electrónico.\n¡Nos vemos en los mejores eventos!"])},
    "successRegistries": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tus registros están en camino. En cuanto confirmemos tu pago los recibirás en tu correo electrónico.\n¡Nos vemos en los mejores eventos!"])},
    "agreedButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enterado"])},
    "oxxoButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ver voucher de OXXO"])},
    "bankTransferButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ver detalles para la transferencia bancaria"])}
  },
  "purchases": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compras realizadas"])},
    "past": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eventos pasados"])},
    "future": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eventos próximos"])},
    "ticketsOption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Boletos"])},
    "registriesOption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Otros Pagos y Registros"])},
    "orderNumber": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Orden #: ", _interpolate(_list(0))])},
    "paymentDate": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Fecha de compra: ", _interpolate(_list(0))])},
    "goToEvent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ir al evento"])},
    "download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descargar código(s) QR"])},
    "ticketsDisclaimer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["* Esto es meramente informativo y no es válido como Boleto. Presenta los boletos que fueron enviados a tu correo electrónico o descárgalos nuevamente mediante el ícono de Descargar."])},
    "registriesDisclaimer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["* Esto es meramente informativo y no es válido como Registro. Presenta los formatos de Registro que fueron enviados a tu correo electrónico o descárgalos nuevamente mediante el ícono de Descargar."])},
    "purchaseTotal": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Total de compra: ", _interpolate(_list(0))])},
    "bankTransfer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transferencia bancaria"])}
  },
  "transactions": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Historial de transacciones"])},
    "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Todas"])},
    "failed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fallidas"])},
    "succeeded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exitosas"])},
    "loadMore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cargar más"])},
    "transactionOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["# Transacción"])},
    "transactionDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fecha (año-mes-día)"])},
    "transactionEventName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evento"])},
    "transactionType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cantidad"])},
    "transactionName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Concepto"])},
    "transactionTotal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total"])},
    "transactionDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detalles"])},
    "transactionMethod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Método de pago"])}
  },
  "bankTransfers": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transferencias bancarias"])},
    "completed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pagado completamente"])},
    "incomplete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parcialmente pagado"])},
    "emptyOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Completa tu pago para poder tener un # de Orden"])},
    "lastUpdateDate": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Última actualización: ", _interpolate(_list(0))])},
    "depositedAmount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monto depositado:"])},
    "remainingAmount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cantidad restante:"])},
    "reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Referencia bancaria"])},
    "referenceLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ir a los detalles del depósito bancario"])},
    "goToPurchases": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ir a Mis Compras"])}
  },
  "footer": {
    "aboutTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acerca de"])},
    "aboutText": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Ticket Dancer® es una plataforma integral con la mayor cantidad de información de eventos del mundo del baile, dando la oportunidad a los promotores de dichos eventos a vender, distribuir y difundir sus boletos, así como a rentar espacios publicitarios dentro de nuestra red de aplicaciones.\nTicket Dancer®, la mejor opción para los promotores de eventos de baile, así como la opción más segura, confiable y la altura de nuestros clientes que gustan del baile.\n\nVersión: ", _interpolate(_list(0)), "\n\nLicencias: Los íconos utilizados en esta app fueron son gracias a Icons8, bajo la licencia Creative Commons Attribution-NoDerivs 3.0 Unported. https://creativecommons.org/licenses/by-nd/3.0/\nhttps://iconos8.es"])},
    "linksTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enlaces"])},
    "linksMission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Misión y Visión"])},
    "linksPolicy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Política de privacidad"])},
    "linksTerms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Términos y condiciones"])},
    "linksFaqs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preguntas frecuentes"])},
    "contactTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contacto"])},
    "rights": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DERECHOS RESERVADOS DE TICKET DANCER ®"])}
  },
  "alerts": {
    "important": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importante"])},
    "confirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmación"])},
    "danger": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Peligro"])},
    "confirmButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continuar"])},
    "saveButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guardar"])},
    "updateButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actualizar"])},
    "deleteButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eliminar"])},
    "stayButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Permanecer"])},
    "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sí"])},
    "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No"])},
    "previous": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anterior"])},
    "new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nueva"])},
    "savedSuccessfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Los datos han sido guardados exitosamente"])}
  },
  "errors": {
    "notFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Página no encontrada"])},
    "generic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Algo salió mal. Por favor intenta nuevamente"])},
    "noConnection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¡Se perdió la conexión!"])},
    "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["requerido"])},
    "valid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["debe ser válido"])},
    "invalidPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["debe tener al menos 6 caractéres, una letra mayúscula, una letra minúscula y un número."])},
    "mismatchPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Las contraseñas no coinciden"])},
    "noInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No se tiene información en este momento. Por favor intenta más tarde"])},
    "noTicketNames": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No todos los boletos están asignados. Por favor asigna y 'Guarda' la información de todos los boletos"])},
    "invalidName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sólo letras son permitidas en los Nombres de los asistentes"])},
    "invalidPaymentMethod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El pago no fue exitoso. Por favor intenta con otro método de pago."])},
    "noNames": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre(s) completo(s) requerido(s)"])},
    "invalidEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uno de tus correos es inválido"])},
    "invalidAge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se requiere un dato numérico entero para el campo de edad"])},
    "noRegistryNames": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No todos los registros están asignados. Por favor asigna y 'Guarda' la información de todos los registros"])}
  },
  "questions": {
    "q1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Qué es Ticket Dancer?"])},
    "q2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Cómo dar de alta una Tarjeta?"])},
    "q3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Qué Métodos de Pago acepta Ticket Dancer?"])},
    "q4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Qué debo hacer si tengo dudas o problemas con mis operaciones bancarias?"])},
    "q5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Puedo facturar mi compra de Ticket Dancer? (Sólo aplica para México)"])},
    "q6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Qué hago si perdí mi(s) boleto(s)?"])},
    "q7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Dónde puedo consultar el Aviso de Privacidad?"])},
    "q8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Puedo pagar con una tarjeta que no este a mi nombre?"])},
    "q9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Dónde puedo consultar las Condiciones de Uso?"])},
    "q10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Ticket Dancer es gratis?"])},
    "q11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Cómo puedo estar tranquilo que mis datos sensibles están seguros?"])}
  },
  "answers": {
    "a1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ticket Dancer es el lugar donde los mejores eventos de baile conviven para ti esperando a que los descubras. Con Ticket Dancer podrás comprar tus boletos desde la aplicación en cualquier lugar y en cualquier momento.\n¡Pero eso no es todo! Dentro de Ticket Dancer encontrarás todos los detalles de las actividades, horarios, artistas, sedes y mucha más información de todos tus eventos favoritos y de los que aún no conoces."])},
    "a2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es muy fácil, al momento de pagar se solicitará la información de pago, aquí podrás guardar tu tarjeta para futuras compras."])},
    "a3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En Ticket Dancer puedes realizar la compra de tus boletos con la mayoría de las tarjetas de Crédito o Débito.\n\nTarjetas de Crédito: VISA, Mastercard y American Express.\nTarjetas de Débito: HSBC, Scotiabank, CitiBanamex, BBVA Bancomer, Santander, IXE, Banco Azteca, Inbursa.\nTarjetas de Crédito a meses sin intereses: BBVA Bancomer, CitiBanamex, Inbursa, Santander, Monex, Afirme, Banorte, BanRegio, HSBC, Scotiabank.\nOXXO*: Puedes realizar pagos en establecimientos de OXXO. Sólo tienes que seleccionar el botón de OXXO al momento de pagar e imprimir la línea de captura mostrada.\n*NOTA: Sólo cuentas con 3 días para realizar tu pago en cajeros OXXO. Pasado ese tiempo la línea de captura expira y debes volver a generarla."])},
    "a4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No te preocupes, Ticket Dancer utiliza un sofisticado y confiable mecanismo para monitorear y mantener seguros todos tus pagos.\n\nDentro de la aplicación de Ticket Dancer puedes ver el estatus de todas tus transacciones. Solo entra a la sección de \"Historial de Transacciones\" dentro de la app.\n\nSi después de esto tienes dudas o problemas por favor no dudes en mandarnos un correo electrónico a soporte", "@", "ticketdancer.com.mx, proporcionándonos Nombre completo, correo electrónico (con el que registraste tu cuenta dentro de Ticket Dancer), un número telefónico y una explicación lo más detalla posible de cuál es tu problema (Si puedes adjuntar al correo cualquier captura de pantalla nos ayudaría infinitamente a resolver cualquier duda o inconveniente más rápido)."])},
    "a5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si estas en México, sí es posible facturar tu compra. Para eso, tendrás que enviarnos un correo a soporte", "@", "ticketdancer.com.mx en no más de 48 horas después de haber realizado tu compra.\n\nTe solicitamos colocar en el asunto del correo \"SOLICITUD DE FACTURA\" y en el cuerpo del correo colocar correo del usuario que realizó la compra, así como el número de orden y los detalles de la compra."])},
    "a6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si perdiste tu(s) boleto(s), tienes antes de 48 horas del comienzo del evento para ponerte en contacto a soporte", "@", "ticketdancer.com.mx y levantar una petición de reenvío de Folio.\n\nTen en cuenta que los boletos perdidos y no reclamados no podrán ingresar al evento. También considera que sólo puedes hacer este proceso una sola vez. ¡Cuida tus boletos!"])},
    "a7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En el Pie de Página podrás encontrar el acceso a la Política de Privacidad."])},
    "a8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Claro. Pero no hagas mal uso de esta opción a menos que tu pareja haya tenido un mal día y necesites levantarle el ánimo con dos boletos para el congreso que tanto le anima..."])},
    "a9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En el Pie de Página podrás encontrar el acceso a las Condiciones de Uso."])},
    "a10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Todo lo bueno en esta vida es gratis y Ticket Dancer no es la excepción."])},
    "a11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ticket Dancer jamás almacenará tu información privada bancaria. Además usamos herramientas con los mejores estándares de la Industria que nos permiten mantener la seguridad y cifrado en todas las operaciones bancarias."])}
  }
}