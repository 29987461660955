<template>
  <ui-top-app-bar content-selector="#main-content" type="fixed" :title="pageTitle" navIcon="menu" @nav="toggleDrawer">
    <template #toolbar="{ toolbarItemClass }">
      <ui-icon-button :class="toolbarItemClass" icon="home" @click.prevent="goHome"></ui-icon-button>
      <template v-if="shoppingCart">
        <ui-badge :class="toolbarItemClass" :count="1">
          <ui-icon-button icon="shopping_cart" @click.prevent="goToShoppingCart"></ui-icon-button>
        </ui-badge>
      </template>
      <template v-else>
        <ui-icon-button :class="toolbarItemClass" icon="shopping_cart" @click.prevent="goToShoppingCart">
        </ui-icon-button>
      </template>
      <ui-menu-anchor :class="toolbarItemClass">
        <ui-icon-button icon="language" @click="openMenu = true">
        </ui-icon-button>
        <ui-menu v-model="openMenu" :items="languages" @selected="changeLanguage($event.value)"></ui-menu>
      </ui-menu-anchor>
    </template>
  </ui-top-app-bar>

  <ui-drawer v-model="openDrawer" type="modal">
    <ui-drawer-header>
      <ui-drawer-title> {{ $t("titles.welcome") }}</ui-drawer-title>
      <ui-drawer-subtitle> {{ name }}</ui-drawer-subtitle>
    </ui-drawer-header>
    <ui-drawer-content>
      <ui-list-divider></ui-list-divider>
      <ui-nav>
        <router-link v-slot="{ href, navigate, isActive }" to="/" custom>
          <ui-nav-item :href="href" :active="isActive" @click="navigate" @click.prevent="toggleDrawer">
            <ui-item-first-content>
              <ui-icon>home</ui-icon>
            </ui-item-first-content>
            <ui-item-text-content>{{ $t("titles.goToHome") }}</ui-item-text-content>
          </ui-nav-item>
        </router-link>
        <template v-if="userLogged">
          <router-link v-slot="{ href, navigate, isActive }" to="/mi-cuenta" custom>
            <ui-nav-item :href="href" :active="isActive" @click="navigate" @click.prevent="toggleDrawer">
              <ui-item-first-content>
                <ui-icon>person</ui-icon>
              </ui-item-first-content>
              <ui-item-text-content>{{ $t("titles.personalSettings") }}</ui-item-text-content>
            </ui-nav-item>
          </router-link>
          <router-link v-slot="{ href, navigate, isActive }" to="/transferencias-bancarias" custom>
            <ui-nav-item :href="href" :active="isActive" @click="navigate" @click.prevent="toggleDrawer">
              <ui-item-first-content>
                <ui-icon>account_balance</ui-icon>
              </ui-item-first-content>
              <ui-item-text-content>{{ $t("bankTransfers.title") }}</ui-item-text-content>
            </ui-nav-item>
          </router-link>
          <router-link v-slot="{ href, navigate, isActive }" to="/transacciones" custom>
            <ui-nav-item :href="href" :active="isActive" @click="navigate" @click.prevent="toggleDrawer">
              <ui-item-first-content>
                <ui-icon>credit_score</ui-icon>
              </ui-item-first-content>
              <ui-item-text-content>{{ $t("transactions.title") }}</ui-item-text-content>
            </ui-nav-item>
          </router-link>
          <router-link v-slot="{ href, navigate, isActive }" to="/compras" custom>
            <ui-nav-item :href="href" :active="isActive" @click="navigate" @click.prevent="toggleDrawer">
              <ui-item-first-content>
                <ui-icon>shopping_basket</ui-icon>
              </ui-item-first-content>
              <ui-item-text-content>{{ $t("titles.purchases") }}</ui-item-text-content>
            </ui-nav-item>
          </router-link>
          <router-link v-slot="{ href, navigate }" to="#" custom>
            <ui-nav-item :href="href" @click="navigate" @click.prevent="logoutFromTD">
              <ui-item-first-content>
                <ui-icon>logout</ui-icon>
              </ui-item-first-content>
              <ui-item-text-content> {{ $t("titles.logout") }} </ui-item-text-content>
            </ui-nav-item>
          </router-link>
        </template>
        <template v-else>
          <router-link v-slot="{ href, navigate, isActive }" to="/login-create" custom>
            <ui-nav-item :href="href" :active="isActive" @click="navigate" @click.prevent="toggleDrawer">
              <ui-item-first-content>
                <ui-icon>login</ui-icon>
              </ui-item-first-content>
              <ui-item-text-content> {{ $t("titles.login") }} </ui-item-text-content>
            </ui-nav-item>
          </router-link>
        </template>
      </ui-nav>
    </ui-drawer-content>
  </ui-drawer>

  <div id="main-content">
    <slot></slot>
  </div>
</template>

<script>

import { mapState, mapActions } from 'pinia';
import { useUserStore } from '@/stores/user';
import { useMainStore } from '@/stores/main';

const languages = [
  {
    text: 'Español',
    value: 'es'
  },
  {
    text: 'English',
    value: 'en'
  }
];

export default {
  name: "TopBar",
  data() {
    return {
      openDrawer: false,
      languages: languages,
      openMenu: false,
    };
  },
  computed: {
    ...mapState(useUserStore, ['userLogged', 'shoppingCart']),
    ...mapState(useMainStore, ['pageTitle']),
    name() {
      return this.userLogged ? this.userLogged.nombre : "";
    },
  },
  methods: {
    ...mapActions(useUserStore, ['logout']),
    ...mapActions(useMainStore, ['updateDefaultLanguage']),
    toggleDrawer() {
      //console.log("toggle");
      this.openDrawer = !this.openDrawer;
    },
    goHome() {
      this.$router.push("/");
    },
    goToShoppingCart() {
      this.$router.push("/shopping-cart");
    },
    logoutFromTD() {
      this.toggleDrawer();
      this.logout();
      this.$toast(this.$t("myAccount.logoutConfirmation"))
      this.$router.push("/");
    },
    changeLanguage(value) {
      this.$i18n.locale = value
      this.updateDefaultLanguage(value)
    }
  },
};
</script>