<template>
    <ui-card outlined>
        <ui-card-content>
            <div class="container">
                <h2 class="container-element">{{ $t('events.artists') }}</h2>
                <ui-spinner class="spinner" active v-show="loadingArtists"></ui-spinner>
                <ui-image-list class="container-element" v-if="withArtists" text-protection>
                    <ui-image-item class="artist" v-for="(artist, index) in artists" :key="index"
                        :bg-image="artist.urlImage">
                        <ui-image-text class="artist-text"> {{ artist.name }} / {{ artist.cityCountry }}</ui-image-text>
                    </ui-image-item>
                </ui-image-list>
                <p class="container-element" v-else>
                    {{ $t('errors.noInfo')}}
                </p>
            </div>
        </ui-card-content>
    </ui-card>
</template>

<script>
import eventsApi from '@/api/EventsApi';

export default {
    name: "TdArtistas",
    props: {
        eventId: String
    },
    data() {
        return {
            artists: [],
            loadingArtists: false,
        }
    },
    async mounted() {
        //console.log("mounted: " + this.eventId)
        await this.loadArtists();
    },
    computed: {
        withArtists () {
            return this.artists.length > 0
        }
    },
    methods: {
        async loadArtists() {
            if (this.eventId) {

                //console.log("Loading Artists of EventId: " + this.eventId)

                this.loadingArtists = true
                try {
                    this.artists = await eventsApi.getArtistsByEvent(this.eventId)
                    this.loadingArtists = false
                } catch (error) {
                    console.log(error)
                    this.loadingArtists = false
                }
            }
        }
    }
}
</script>
<style scoped>
.container {
    position: relative;
    min-height: 480px;
}

.spinner {
    position: absolute;
    margin: auto;
    top: 120px;
    left: 0;
    right: 0;
}

.container-element {
    margin: 10px 30px;
}

.artist {
    width: 300px;
    height: 300px;
    margin: 5px
}

.artist-text {
    font-size: 0.3em;
}
</style>