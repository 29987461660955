import { URLS } from "@/utils/constants";
import commonsApi from "./CommonsApi";
import i18n from "@/i18n";
import { useMainStore } from "@/stores/main";

export default {

    async getMainEvents() {

        const mainStore = useMainStore();

        let capcha = await commonsApi.getCaptcha();

        let response = await fetch(URLS.GET_MAIN_EVENTS + "?language=" + mainStore.defaultLanguage, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                key: capcha,
            },
        });

        if (response.status >= 200 && response.status < 300) { // Success
            let mainEventsData = await response.json();
            return mainEventsData;
        } else if (response.status === 409) { // 409 = API Error
            let error = await response.json();
            throw error
        }
        else { // Generic Error
            throw {
                code: response.status, mensaje: i18n.global.t("errors.generic")
            }
        }
    },

    async getPromotions() {

        let capcha = await commonsApi.getCaptcha();

        let response = await fetch(URLS.GET_PROMOTIONS, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                key: capcha,
            },
        });

        if (response.status >= 200 && response.status < 300) { // Success
            let promotionsData = await response.json();
            return promotionsData;
        } else if (response.status === 409) { // 409 = API Error
            let error = await response.json();
            throw error
        }
        else { // Generic Error
            throw {
                code: response.status, mensaje: i18n.global.t("errors.generic")
            }
        }
    },

    async getEvents(page, pageSize) {

        const mainStore = useMainStore();

        let capcha = await commonsApi.getCaptcha();

        let response = await fetch(URLS.GET_ALL_EVENTS + "?language=" + mainStore.defaultLanguage + "&page=" + page + "&size=" + pageSize, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                key: capcha,
            },
        });

        if (response.status >= 200 && response.status < 300) { // Success
            let mainEventsData = await response.json();
            return mainEventsData;
        } else if (response.status === 409) { // 409 = API Error
            let error = await response.json();
            throw error
        }
        else { // Generic Error
            throw {
                code: response.status, mensaje: i18n.global.t("errors.generic")
            }
        }
    },

    async getEventById(eventId) {

        const mainStore = useMainStore();

        let capcha = await commonsApi.getCaptcha();

        let response = await fetch(URLS.GET_EVENT_BY_ID + "?language=" + mainStore.defaultLanguage + "&eventId=" + eventId, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                key: capcha,
            },
        });

        if (response.status === 204) { // No content
            return null
        }
        else if (response.status === 200) { // Success
            let eventData = await response.json();
            return eventData;
        } else if (response.status === 409) { // 409 = API Error
            let error = await response.json();
            throw error
        }
        else { // Generic Error
            throw {
                code: response.status, mensaje: i18n.global.t("errors.generic")
            }
        }
    },

    async getArtistsByEvent(eventId) {
        let capcha = await commonsApi.getCaptcha();

        let response = await fetch(URLS.GET_MEMBERS_BY_EVENT + "?eventId=" + eventId + "&type=ARTIST", {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                key: capcha,
            },
        });

        if (response.status >= 200 && response.status < 300) { // Success
            let artistsData = await response.json();
            return artistsData;
        } else if (response.status === 409) { // 409 = API Error
            let error = await response.json();
            throw error
        }
        else { // Generic Error
            throw {
                code: response.status, mensaje: i18n.global.t("errors.generic")
            }
        }
    },

    async getTicketsByEventId(currency, eventId) {

        const mainStore = useMainStore();

        let capcha = await commonsApi.getCaptcha();

        let response = await fetch(URLS.GET_TICKETS_BY_EVENT + "?language=" + mainStore.defaultLanguage + "&eventId=" + eventId + "&currency=" + currency, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                key: capcha
            },
        });

        if (response.status >= 200 && response.status < 300) { // Success
            let ticketsData = await response.json();
            return ticketsData;
        } else if (response.status === 409) { // 409 = API Error
            let error = await response.json();
            throw error
        }
        else { // Generic Error
            throw {
                code: response.status, mensaje: i18n.global.t("errors.generic")
            }
        }
    },

    async getRegistriesByEventId(currency, eventId) {

        const mainStore = useMainStore();

        let capcha = await commonsApi.getCaptcha();

        let response = await fetch(URLS.GET_REGISTRIES_BY_EVENT + "?language=" + mainStore.defaultLanguage + "&eventId=" + eventId + "&currency=" + currency, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                key: capcha,
            },
        });

        if (response.status >= 200 && response.status < 300) { // Success
            let registriesData = await response.json();
            return registriesData;
        } else if (response.status === 409) { // 409 = API Error
            let error = await response.json();
            throw error
        }
        else { // Generic Error
            throw {
                code: response.status, mensaje: i18n.global.t("errors.generic")
            }
        }
    },

    async getRegisterTypeById(token, registerTypeId) {

        const mainStore = useMainStore();

        let capcha = await commonsApi.getCaptcha();

        let response = await fetch(URLS.GET_REGISTER_TYPE_BY_ID + "/" + mainStore.defaultLanguage + "/" + registerTypeId, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                key: capcha,
                vyliwi: token
            },
        });

        if (response.status >= 200 && response.status < 300) { // Success
            let registerTypeData = await response.json();
            return registerTypeData;
        } else { // API or Generic Error
            throw {
                code: response.status, mensaje: i18n.global.t("errors.generic")
            }
        }
    }
}