<template>
    <ui-skeleton class="container" :loading="eventLoading" active :paragraph="{ rows: 20 }">
        <img class="header-image" :src="tdEvent.urlBanner" />
        <ui-card outlined class="header-video" v-show="tdEvent.videoURL">
            <ui-card-content>
                <video-player :options="videoOptions" />
            </ui-card-content>
        </ui-card>
        <ui-grid class="info-grid">
            <ui-grid-cell class="info-grid-cell" columns="7">
                <td-artistas :event-id="tdEvent.id"></td-artistas>
            </ui-grid-cell>
            <ui-grid-cell class="info-grid-cell" columns="5">
                <td-information class="info-grid-element" :info="tdEvent.description"></td-information>
                <td-location class="info-grid-element" :location="tdEvent.location"></td-location>
            </ui-grid-cell>
        </ui-grid>
        <div class="buttons-container">
            <ui-button raised class="button" id="registry-button" @click.prevent="goToSelectRegisterType">
                {{ $t('registries.selectRegistriesButton') }}
                <template #after>
                    <ui-icon>request_quote</ui-icon>
                </template>
            </ui-button>
            <ui-button raised class="button" @click.prevent="goToSelectTicket">
                {{ $t('events.selectTicketsButton') }}
                <template #after>
                    <ui-icon>confirmation_number</ui-icon>
                </template>
            </ui-button>
        </div>
        <error-component v-show="errorLoad" :message="errorMessage" />
    </ui-skeleton>
</template>

<script>
import eventsApi from '@/api/EventsApi';
import ErrorComponent from '@/components/common/ErrorComponent.vue';
import VideoPlayer from '@/components/common/VideoPlayer.vue'
import { useMainStore } from '@/stores/main';
import TdArtistas from '@/components/eventDetail/TdArtistas.vue';
import TdInformation from '@/components/eventDetail/TdInformation.vue';
import TdLocation from '@/components/eventDetail/TdLocation.vue';
import { analytics, logEvent } from "@/utils/firebase"

export default {
    name: "EventDetailView",
    components: { ErrorComponent, VideoPlayer, TdArtistas, TdInformation, TdLocation },
    data() {
        return {
            tdEvent: Object,
            eventLoading: false,
            errorLoad: false,
            errorMessage: "",
            protocol: window.location.protocol,
            origin: window.location.origin
        }
    },
    computed: {
        videoOptions() {
            return {
                autoplay: false,
                controls: true,
                loop: false,
                aspectRatio: '16:9',
                techOrder: ["youtube"],
                sources: [{ type: "video/youtube", src: `${this.protocol}//www.youtube.com/watch?v=${this.tdEvent.videoURL}`, youtube: { origin: `${this.origin}`, enablejsapi: 1 } }]
            }
        }
    },
    async mounted() {
        //console.log('mounted')
        await this.loadEvent(this.$route.params.eventId)
    },
    methods: {
        async loadEvent(eventId) {
            this.eventLoading = true;
            this.errorLoad = false;

            try {
                this.tdEvent = await eventsApi.getEventById(eventId)

                if (this.tdEvent) {

                    const store = useMainStore()
                    store.$patch({ pageTitle: this.tdEvent.name })

                    logEvent(analytics, 'view_event', {
                        event_id: this.tdEvent.id,
                        event_name: this.tdEvent.name
                    })

                    this.eventLoading = false;

                } else {
                    // Event not found
                    this.$router.push("/not-found")
                }

            } catch (error) {
                this.eventLoading = false;
                // API or Network Error
                this.errorLoad = true;
                this.errorMessage = error.mensaje;
            }
        },
        goToSelectTicket() {
            this.$router.push({
                name: 'selectTicket',
                params: {
                    eventId: this.tdEvent.id
                }
            });
        },
        goToSelectRegisterType() {
            this.$router.push({
                name: 'selectRegisterType',
                params: {
                    eventId: this.tdEvent.id
                }
            });
        }
    }
}
</script>

<style scoped>
.header-image {
    width: 100%;
    height: auto;
    position: relative;
}

.header-video {
    width: 70%;
    height: auto;
    position: absolute;
    margin: auto;
    top: 100px;
    left: 0;
    right: 0;
}

.buttons-container {
    position: -webkit-sticky;
    position: sticky;
    bottom: 0;
    background-color: #ffffffcc;
    z-index: 1;
    min-height: 80px;
    padding-right: 20px;
    text-align: right;
}

.button {
    margin: 16px 12px;
    height: 48px;
    font-size: medium;
}

#registry-button {
    background: #e5a946;
}

.info-grid {
    min-height: 500px;
}

.info-grid-cell {
    min-height: 200px;
}

.info-grid-element {
    margin-bottom: 20px;
}
</style>