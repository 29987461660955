const BASE_URL = process.env.VUE_APP_BASE_API_URL;

export const URLS = Object.freeze({
    BASE_URL: BASE_URL,
    CAPTCHA: BASE_URL + "commons/capcha",
    USER_CREATE: BASE_URL + "userservices/create",
    USER_LOGIN: BASE_URL + "userservices/login",
    GOOGLE_USER_SIGNIN: BASE_URL + "userservices/sign_in_with_google",
    APPLE_USER_SIGNIN: BASE_URL + "userservices/sign_in_with_apple",
    RESEND_VALIDATION_EMAIL: BASE_URL + "userservices/request_new_validation_token",
    REQUEST_FORGOT_CODE: BASE_URL + "userservices/request_reset",
    VALIDATE_FORGOT_CODE: BASE_URL + "userservices/check_reset_token",
    UPDATE_USER_PASSWORD: BASE_URL + "userservices/resetPassword",
    GET_USER_DATA: BASE_URL + "userservices/get_user_data",
    CHECK_PASSWORD: BASE_URL + "userservices/password_validation",
    UPDATE_USER_DATA: BASE_URL + "userservices/update",
    DELETE_USER: BASE_URL + "userservices/delete_user",
    CHECK_SHOPPING_CART: BASE_URL + "shopping/check_shopping_cart",
    GET_MAIN_EVENTS: BASE_URL + "eventservices/list_main_events_nologin",
    GET_PROMOTIONS: BASE_URL + "promotionservices/getAllPromotions",
    GET_ALL_EVENTS: BASE_URL + "eventservices/list_all_events_no_logged",
    GET_EVENT_BY_ID: BASE_URL + "eventservices/getEventByIdNoLogin",
    GET_MEMBERS_BY_EVENT: BASE_URL + "eventservices/getMembersByEventNoLogin",
    GET_TICKETS_BY_EVENT: BASE_URL + "eventservices/get_available_event_tickets",
    GET_REGISTRIES_BY_EVENT: BASE_URL + "eventservices/get_available_event_register_types",
    CREATE_SHOPPING_CART: BASE_URL + "shopping/create_shopping_cart",
    REQUEST_PAYMENT_SHEET: BASE_URL + "payservices/request_payment_sheet",
    REDEEM_COUPON: BASE_URL + "shopping/redeem",
    CANCEL_REDEEM_COUPON: BASE_URL + "shopping/cancel_redeem",
    UPDATE_SHOPPING_CART: BASE_URL + "shopping/update_shopping_cart",
    DELETE_SHOPPING_CART: BASE_URL + "shopping/delete_shopping_cart",
    DISABLE_SHOPPING_CART: BASE_URL + "shopping/disable_shopping_cart",
    CHECK_SUBMISSION_SHOPPING_CART: BASE_URL + "shopping/check_submission",
    GET_REGISTER_TYPE_BY_ID: BASE_URL + "register_type_services/get_for_checkout",
    REQUEST_REGISTER_PAYMENT_SHEET: BASE_URL + "payservices/request_register_payment_sheet",
    SUBMIT_REGISTRY_FORM: BASE_URL + "registryservices/submit_registry_form",
    GET_PAST_PAYMENTS: BASE_URL + "payservices/get_before_payments",
    GET_FUTURE_PAYMENTS: BASE_URL + "payservices/get_after_payments",
    GET_PAST_REGISTRY_PAYMENTS: BASE_URL + "registryservices/get_before_registry_payments",
    GET_FUTURE_REGISTRY_PAYMENTS: BASE_URL + "registryservices/get_after_registry_payments",
    DOWNLOAD_TICKETS: BASE_URL + "payservices/downloadTicketsPdf",
    DOWNLOAD_REGISTRIES: BASE_URL + "registryservices/downloadRegistriesPdf",
    GET_PAYMENTS_HISTORY: BASE_URL + "pay_history_services/list_all",
    GET_REGISTRY_PAYMENTS_HISTORY: BASE_URL + "registry_history_services/list_all",
    RE_CAPTCHA_ASSESSMENT: BASE_URL + "commons/re-captcha-assessment",
    LIST_CARDS: BASE_URL + "payservices/list_cards",
    LIST_PLANS_FOR_CARD: BASE_URL + "payservices/list_plans_for_card",
    DELETE_CARD: BASE_URL + "payservices/delete_card",
    REQUEST_SETUP_INTENT: BASE_URL + "payservices/request_setup_intent",
    CONFIRM_PAYMENT_INTENT: BASE_URL + "payservices/confirm_payment_intent",
    PAYMENT_BANK_TRANSFERS: BASE_URL + "payment_bank_transfer/get_payment_bank_transfers_by_user",
    REGISTRY_PAYMENT_BANK_TRANSFERS: BASE_URL + "registry_payment_bank_transfer/get_registry_payment_bank_transfers_by_user",
})

export const LS_KEYS = Object.freeze({
    USER_LOGIN_KEY: "ticketdancer_web_user_key",
    USER_SHOPPING_CART_KEY: "ticketdancer_user_shopping_cart_key",
    USER_DEFAULT_LOCALE: "ticketdancer_def_locale"
})

export const RECAPTCHA_ACTIONS = Object.freeze({
    PAGE_LOAD_LOGIN_CREATE: "PAGE_LOAD_LOGIN_CREATE",
    PAGE_LOAD_SHOPPING_CART: "PAGE_LOAD_SHOPPING_CART",
    PAGE_LOAD_REGISTRY: "PAGE_LOAD_REGISTRY",
    PAGE_LOAD_FORGOT_PASSWORD: "PAGE_LOAD_FORGOT_PASSWORD",
    LOGIN: "LOGIN",
    CREATE_USER: "CREATE_USER",
    SAVE_SHOPPING_CART: "SAVE_SHOPPING_CART",
    SAVE_REGISTRIES: "SAVE_REGISTRIES",
    SEND_FORGOT_PASSWORD_CODE: "SEND_FORGOT_PASSWORD_CODE",
    VALIDATE_FORGOT_PASSWORD_CODE: "VALIDATE_FORGOT_PASSWORD_CODE"
})

export const SELECT_VALUES = Object.freeze({
    TICKETS_QUANTITY: [
        {
            label: '1',
            value: 1
        },
        {
            label: '2',
            value: 2
        },
        {
            label: '3',
            value: 3
        },
        {
            label: '4',
            value: 4
        },
        {
            label: '5',
            value: 5
        },
        {
            label: '6',
            value: 6
        },
        {
            label: '7',
            value: 7
        },
        {
            label: '8',
            value: 8
        },
        {
            label: '9',
            value: 9
        },
        {
            label: '10',
            value: 10
        },
        {
            label: '11',
            value: 11
        },
        {
            label: '12',
            value: 12
        },
        {
            label: '13',
            value: 13
        },
        {
            label: '14',
            value: 14
        },
        {
            label: '15',
            value: 15
        },
        {
            label: '16',
            value: 16
        },
        {
            label: '17',
            value: 17
        },
        {
            label: '18',
            value: 18
        },
        {
            label: '19',
            value: 19
        },
        {
            label: '20',
            value: 20
        },
    ]
})